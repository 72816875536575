body {
    padding-top: 117px;
    color: $gray-2;
}
.navbar {
    border-bottom: 8px solid $primary;
}
.navbar-light .navbar-nav .nav-link {
    color: $gray-1;
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
    color: $primary;
}
@each $color, $value in $colors {
    @include bg-variant(".bg-#{$color}", $value, true);
}
@each $color, $value in $colors {
    @include text-emphasis-variant(".text-#{$color}", $value, true);
}
.text-black {
    color: #000000;
}
.bg-black {
    background-color: #000000;
}
.border-gold {
    border-bottom: 12px solid $primary;
}
.title-logo {
    width: 60px;
}
.alert ul {
    padding-left: 15px;
}
.display-5 {
    font-size: 2.5rem;
}
@media (max-width: 767.98px) {
    .display-5 {
        font-size: 1.8rem;
    }
    .display-3 {
        font-size: 3rem;
    }
    .display-1 {
        font-size: 5rem;
    }
}
@media (max-width: 575.98px) {
    body {
        padding-top: 87px;
    }
    .main-logo {
        width: 200px;
    }
    .title-logo {
        width: 32px;
        // width: 46px;
    }
    .h2 {
        font-size: 1.875rem;
    }
    .h3 {
        font-size: 22px;
    }
    .display-5 {
        font-size: 1rem;
    }
    .display-4 {
        font-size: 28px;    
        // font-size: 2rem;    
    }
    .display-3 {
        font-size: 1.75rem;
    }
    .display-1 {
        font-size: 2.5rem;
    }
}